/* global google */

import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

export const initFacilityMobile = () => {
	document.querySelector('#toggle_fac_info').addEventListener('click', () => {
		document.querySelector('#toggle_fac_info').switchAttribute('state', 'open', 'closed');
		document.querySelector('#facility_info').switchAttribute('state', 'open', 'closed');
	});
};

export const initFacilityCaro = () => {
	window.facImgsCaro = AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		images: window.facCaroImgs,
		imagesAsBackgrounds: true,
		useChevrons: false,
	});
};

export const facilityMap = () => {

	const mapHelper = getMapsHelper();
	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#facility_map'),
				locationElementSelector: '#facility_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a target="_blank" href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/map_pin.svg" alt="Visit Facility" /></a>`
					};
				}
			});

			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});
};

export const initFacilityMapToggle = () => {
	
	let generateFacMap = false;
	
	document.querySelector('#toggle_fac_map').addEventListener('click', () => {
		document.querySelector("#facility_map").switchAttribute('state', 'hide', 'show');
		document.querySelector("#toggle_fac_map").switchAttribute('state', 'off', 'on');
		if(!generateFacMap) {
			generateFacMap = true;
			facilityMap();
		}
	});
};

export const initFacilityTabs = () => {
	let generateNearbyMap = false;
	const tabs = document.querySelectorAll('#facility_content_tabs button');
	const tabContent = document.querySelectorAll('.facility_content_tab');
	document.querySelector('#facility_content_tabs').addEventListener('click', e => {
		const clickedTab = e.target.closest('button');

		if(clickedTab) {
			tabs.forEach(tab => {
				tab.removeAttribute('active');
				if(clickedTab.getAttribute('tab') === 'rates') {
					tab.classList.remove('shadow');
				} else {
					tab.classList.add('shadow');
				}
			});

			clickedTab.setAttribute('active', true);

			if(clickedTab.getAttribute('tab') === 'rates') {
				document.querySelector('#facility_filters').setAttribute('show', true);
			} else {
				document.querySelector('#facility_filters').removeAttribute('show');
			}

			tabContent.forEach(tab => {
				tab.removeAttribute('active');
	
				if(tab.getAttribute('tab') === clickedTab.getAttribute('tab')) {
					tab.setAttribute('active', true);
				}
			});

			if(!generateNearbyMap && clickedTab.getAttribute('tab') === 'nearby') {
				initNearbyMap();
				generateNearbyMap = true;
			}
		}
	});
};

export const initFacilityFilters = () => {
	const filters = document.querySelectorAll('#facility_filters button');
	const rows = document.querySelectorAll('.rates_row');
	const noUnits = document.querySelector('#no_units_filter');

	document.querySelector('#facility_filters').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');

		if(clickedFilter) {
			if(clickedFilter.hasAttribute('active')) {
				clickedFilter.removeAttribute('active');
			} else {
				filters.forEach(filter => filter.removeAttribute('active'));
				clickedFilter.setAttribute('active', true);
			}

			const activeFilter = document.querySelector('#facility_filters button[active]');

			console.log(activeFilter);

			let numUnits = 0;
	
			rows.forEach(row => {
				row.setAttribute('hide', true);
	
				if((activeFilter && activeFilter.getAttribute('size') === row.getAttribute('size')) || !activeFilter) {
					row.removeAttribute('hide');
					numUnits++;
				}
			});

			if(numUnits) {
				noUnits.style.display = 'none';
			} else {
				noUnits.style.display = 'block';
			}
		}

	});
};

export const initNearbyMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {

			window.nearbyMapBounds = new google.maps.LatLngBounds();

			window.nearbyMap = mapHelper.createMap({
				element: document.querySelector('#facility_nearby_map'),
				locationElementSelector: '.facility_nearby_item',
				useRichmarker: true,
				markerReducer: el => {
					window.nearbyMapBounds.extend(new google.maps.LatLng(el.getAttribute('lat'), el.getAttribute('lng')));
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<a class="map_pin" href="${el.getAttribute('link')}">
								<img src="/static/images/icons/map_pin.svg" alt="Visit facility" />
								<span>${el.getAttribute('count')}</span>
							</a>
						`
					};
				},
			});

			google.maps.event.addListenerOnce(window.nearbyMap, 'idle', () => {
				if(document.querySelectorAll('.facility_nearby_item').length < 2) {		
					window.nearbyMap.setZoom(15);
				}

				document.querySelectorAll('.facility_nearby_item').forEach(i => {
					const markerData = mapHelper.getMarker(i);
					markerData.element.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						i.classList.add('hover');
					});
					markerData.element.addEventListener('mouseleave', () => {
						markerData.element.classList.remove('hover');
						i.classList.remove('hover');
					});
				});
			});
		});
};